@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700;800&display=swap');

html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
} */

#root {
    height: 100%;
}

/* #root > div[role="group"][tabindex] {
  height: 100%;
} */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    /* border: 1px solid green; */
    /* -webkit-text-fill-color: green; */
    -webkit-box-shadow: 0 0 0px 1000px #a7934440 inset;
    transition: background-color 5000s ease-in-out 0s;
}

#root {
    background-color: #fafafa;
    background-image: url('_assets/images/bg-mobile.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
}

@media screen and (min-width: 600px) {
    #root {
        background-image: url('_assets/images/bg-desktop.svg');
        background-size: contain;
        background-position-x: right;
        background-position-y: 35vh;
    }
}
